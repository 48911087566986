import { Cart, CartContent } from '../../typings/base'

export const ACTIONS = {
  CREATE_CART_REQUEST: 'CREATE_CART_REQUEST',
  CREATE_CART_SUCCESS: 'CREATE_CART_SUCCESS',
  CREATE_CART_FAILURE: 'CREATE_CART_FAILURE',
  CREATE_SUBSCRIPTION_CART_REQUEST: 'CREATE_SUBSCRIPTION_CART_REQUEST',
  CREATE_SUBSCRIPTION_CART_SUCCESS: 'CREATE_SUBSCRIPTION_CART_SUCCESS',
  CREATE_SUBSCRIPTION_CART_FAILURE: 'CREATE_SUBSCRIPTION_CART_FAILURE',
  DELETE_CART_REQUEST: 'DELETE_CART_REQUEST',
  DELETE_CART_SUCCESS: 'DELETE_CART_SUCCESS',
  DELETE_CART_FAILURE: 'DELETE_CART_FAILURE',
  DELETE_CART_DP_REQUEST: 'DELETE_CART_DP_REQUEST',
  DELETE_CART_DP_SUCCESS: 'DELETE_CART_DP_SUCCESS',
  DELETE_CART_DP_FAILURE: 'DELETE_CART_DP_FAILURE',
  ADD_PRODUCT_REQUEST: 'ADD_PRODUCT_REQUEST',
  ADD_PRODUCT_SUCCESS: 'ADD_PRODUCT_SUCCESS',
  ADD_PRODUCT_FAILURE: 'ADD_PRODUCT_FAILURE',
  ADD_MULTIPLE_PRODUCT_REQUEST: 'ADD_MULTIPLE_PRODUCT_REQUEST',
  ADD_MULTIPLE_PRODUCT_SUCCESS: 'ADD_MULTIPLE_PRODUCT_SUCCESS',
  ADD_MULTIPLE_PRODUCT_FAILURE: 'ADD_MULTIPLE_PRODUCT_FAILURE',
  UPDATE_CART_SUBSCRIPTION_REQUEST: 'UPDATE_CART_SUBSCRIPTION_REQUEST',
  UPDATE_CART_SUBSCRIPTION_SUCCESS: 'UPDATE_CART_SUBSCRIPTION_SUCCESS',
  UPDATE_CART_SUBSCRIPTION_FAILURE: 'UPDATE_CART_SUBSCRIPTION_FAILURE',
  PUT_PRODUCT_REQUEST: 'PUT_PRODUCT_REQUEST',
  PUT_PRODUCT_SUCCESS: 'PUT_PRODUCT_SUCCESS',
  PUT_PRODUCT_FAILURE: 'PUT_PRODUCT_FAILURE',
  PATCH_CART_DATES_REQUEST: 'PATCH_CART_DATES_REQUEST',
  PATCH_CART_DATES_SUCCESS: 'PATCH_CART_DATES_SUCCESS',
  PATCH_CART_DATES_FAILURE: 'PATCH_CART_DATES_FAILURE',
  DELETE_PRODUCT_REQUEST: 'DELETE_PRODUCT_REQUEST',
  DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
  DELETE_PRODUCT_FAILURE: 'DELETE_PRODUCT_FAILURE',
  GET_CART_REQUEST: 'GET_CART_REQUEST',
  GET_CART_SUCCESS: 'GET_CART_SUCCESS',
  GET_CART_FAILURE: 'GET_CART_FAILURE',
  PUT_CART_COUPON_REQUEST: 'PUT_CART_COUPON_REQUEST',
  PUT_CART_COUPON_SUCCESS: 'PUT_CART_COUPON_SUCCESS',
  PUT_CART_COUPON_FAILURE: 'PUT_CART_COUPON_FAILURE',
  SET_ADDRESS_TYPE_CART: 'SET_ADDRESS_TYPE_CART',
  SET_PARCEL_ID_CART: 'SET_PARCEL_ID_CART',
  DELETE_PRODUCT_SECONDHAND_REQUEST: 'DELETE_PRODUCT_SECONDHAND_REQUEST',
  DELETE_PRODUCT_SECONDHAND_SUCCESS: 'DELETE_PRODUCT_SECONDHAND_SUCCESS',
  DELETE_PRODUCT_SECONDHAND_FAILURE: 'DELETE_PRODUCT_SECONDHAND_FAILURE',
  EMPTY_CART: 'EMPTY_CART',
}

export const EMPTY_CART = () => ({
  type: ACTIONS.EMPTY_CART,
})

export const CREATE_CART_REQUEST = () => ({
  type: ACTIONS.CREATE_CART_REQUEST,
})

export const CREATE_CART_SUCCESS = (cart: Cart) => ({
  type: ACTIONS.CREATE_CART_SUCCESS,
  cart,
})

export const CREATE_CART_FAILURE = (error) => ({
  type: ACTIONS.CREATE_CART_FAILURE,
  error,
})

export const CREATE_SUBSCRIPTION_CART_REQUEST = () => ({
  type: ACTIONS.CREATE_SUBSCRIPTION_CART_REQUEST,
})

export const CREATE_SUBSCRIPTION_CART_SUCCESS = (cart: Cart) => ({
  type: ACTIONS.CREATE_SUBSCRIPTION_CART_SUCCESS,
  cart,
})

export const CREATE_SUBSCRIPTION_CART_FAILURE = (error) => ({
  type: ACTIONS.CREATE_SUBSCRIPTION_CART_FAILURE,
  error,
})

export const DELETE_CART_REQUEST = () => ({
  type: ACTIONS.DELETE_CART_REQUEST,
})

export const DELETE_CART_SUCCESS = (cart) => ({
  type: ACTIONS.DELETE_CART_SUCCESS,
  cart,
})

export const DELETE_CART_FAILURE = (err) => ({
  type: ACTIONS.DELETE_CART_FAILURE,
  err,
})

export const DELETE_CART_DP_REQUEST = () => ({
  type: ACTIONS.DELETE_CART_DP_REQUEST,
})

export const DELETE_CART_DP_SUCCESS = (cart) => ({
  type: ACTIONS.DELETE_CART_DP_SUCCESS,
  cart,
})

export const DELETE_CART_DP_FAILURE = (err) => ({
  type: ACTIONS.DELETE_CART_DP_FAILURE,
  err,
})

export const ADD_PRODUCT_REQUEST = () => ({
  type: ACTIONS.ADD_PRODUCT_REQUEST,
})

export const ADD_PRODUCT_SUCCESS = (cart: CartContent) => ({
  type: ACTIONS.ADD_PRODUCT_SUCCESS,
  cart,
})

export const ADD_PRODUCT_FAILURE = (err) => ({
  type: ACTIONS.ADD_PRODUCT_FAILURE,
  err,
})

export const ADD_MULTIPLE_PRODUCT_REQUEST = () => ({
  type: ACTIONS.ADD_MULTIPLE_PRODUCT_REQUEST,
})

export const ADD_MULTIPLE_PRODUCT_SUCCESS = (cart: Cart) => ({
  type: ACTIONS.ADD_MULTIPLE_PRODUCT_SUCCESS,
  cart,
})

export const ADD_MULTIPLE_PRODUCT_FAILURE = (err) => ({
  type: ACTIONS.ADD_MULTIPLE_PRODUCT_FAILURE,
  err,
})

export const PUT_PRODUCT_REQUEST = () => ({
  type: ACTIONS.PUT_PRODUCT_REQUEST,
})

export const PUT_PRODUCT_SUCCESS = (cart: CartContent) => ({
  type: ACTIONS.PUT_PRODUCT_SUCCESS,
  cart,
})

export const PUT_PRODUCT_FAILURE = (err) => ({
  type: ACTIONS.PUT_PRODUCT_FAILURE,
  err,
})

export const GET_CART_REQUEST = () => ({
  type: ACTIONS.GET_CART_REQUEST,
})

export const GET_CART_SUCCESS = (cart: Cart) => ({
  type: ACTIONS.GET_CART_SUCCESS,
  cart,
})

export const GET_CART_FAILURE = (error) => ({
  type: ACTIONS.GET_CART_FAILURE,
  error,
})

export const DELETE_PRODUCT_REQUEST = () => ({
  type: ACTIONS.DELETE_PRODUCT_REQUEST,
})

export const DELETE_PRODUCT_SUCCESS = (cart: CartContent) => ({
  type: ACTIONS.DELETE_PRODUCT_SUCCESS,
  cart,
})

export const DELETE_PRODUCT_FAILURE = (err) => ({
  type: ACTIONS.DELETE_PRODUCT_FAILURE,
  err,
})

export const DELETE_PRODUCT_SECONDHAND_REQUEST = () => ({
  type: ACTIONS.DELETE_PRODUCT_SECONDHAND_REQUEST,
})

export const DELETE_PRODUCT_SECONDHAND_SUCCESS = (cart: CartContent) => ({
  type: ACTIONS.DELETE_PRODUCT_SECONDHAND_SUCCESS,
  cart,
})

export const DELETE_PRODUCT_SECONDHAND_FAILURE = (err) => ({
  type: ACTIONS.DELETE_PRODUCT_SECONDHAND_FAILURE,
  err,
})

export const UPDATE_CART_SUBSCRIPTION_REQUEST = () => ({
  type: ACTIONS.UPDATE_CART_SUBSCRIPTION_REQUEST,
})

export const UPDATE_CART_SUBSCRIPTION_SUCCESS = (cart: Cart) => ({
  type: ACTIONS.UPDATE_CART_SUBSCRIPTION_SUCCESS,
  cart,
})

export const UPDATE_CART_SUBSCRIPTION_FAILURE = (err) => ({
  type: ACTIONS.UPDATE_CART_SUBSCRIPTION_FAILURE,
  err,
})

export const PATCH_CART_DATES_REQUEST = () => ({
  type: ACTIONS.PATCH_CART_DATES_REQUEST,
})

export const PATCH_CART_DATES_SUCCESS = (cart: Cart) => ({
  type: ACTIONS.PATCH_CART_DATES_SUCCESS,
  cart,
})

export const PATCH_CART_DATES_FAILURE = (err) => ({
  type: ACTIONS.PATCH_CART_DATES_FAILURE,
  err,
})

export const PUT_CART_COUPON_REQUEST = () => ({
  type: ACTIONS.PUT_CART_COUPON_REQUEST,
})

export const PUT_CART_COUPON_SUCCESS = (cart: Cart) => ({
  type: ACTIONS.PUT_CART_COUPON_SUCCESS,
  cart,
})

export const PUT_CART_COUPON_FAILURE = (err) => ({
  type: ACTIONS.PUT_CART_COUPON_FAILURE,
  err,
})

export const SET_ADDRESS_TYPE_CART = (type_name) => ({
  type: ACTIONS.SET_ADDRESS_TYPE_CART,
  type_name,
})

export const SET_PARCEL_ID_CART = (rmsParcelShopId) => ({
  type: ACTIONS.SET_PARCEL_ID_CART,
  rmsParcelShopId,
})
