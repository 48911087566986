export const actions = {
  SET_SHOW_BASKET: 'SET_SHOW_BASKET',
  SET_OPEN_NO_MIXED_BASKET_MODAL: 'SET_OPEN_NO_MIXED_BASKET_MODAL',
}

export const setShowBasket = (isOpen) => ({
  type: 'SET_SHOW_BASKET',
  isOpen: isOpen,
})

export const setOpenNoMixedBasketModal = (isOpen) => ({
  type: actions.SET_OPEN_NO_MIXED_BASKET_MODAL,
  isOpen,
})
